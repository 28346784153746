import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ComplaintPolicy from '../components/ComplaintPolicy/complaint-policy';

const ComplaintPolicyPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Complaint Policy" />
    <ComplaintPolicy />
  </Layout>
);

ComplaintPolicyPage.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default ComplaintPolicyPage;
