import React, { useEffect, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { getContentfulClient } from '../../lib/roptoClientLibrary';

import './complaint-policy.scss';

const ComplaintPolicy = () => {
  const [complaintPolicy, setComplaintPolicy] = useState();

  const contentfulClientOpts = {
    accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
    space: process.env.CONTENTFUL_SPACE_ID,
  };

  const contentfulPPClient = getContentfulClient(
    contentfulClientOpts.accessToken,
    contentfulClientOpts.space,
  );


  useEffect(() => {
    async function fetchPP() {
      const response = await contentfulPPClient.getEntry(
        process.env.CONTENTFUL_CP_ID,
      );
      setComplaintPolicy(response);
    }
    fetchPP();
  }, []);

  return (
    <div className="complaint-policy">
      <div className="complaint-policy__container">
        { complaintPolicy
          && <h1 className="complaint-policy__title">{complaintPolicy.fields.title}</h1>
        }
        <div className="complaint-policy__content">
          { complaintPolicy && documentToReactComponents(complaintPolicy.fields.body) }
        </div>
      </div>
    </div>
  );
};

export default ComplaintPolicy;
